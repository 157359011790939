import React, { useEffect, useState } from 'react';

import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  MEMBER_TYPE,
  Survey,
  ResultPageIncentiveSurveyData,
  Member,
} from 'Containers/ProfilePage/types';
import { ContentfulInfoBar } from 'ContentfulWrappers/ContentfulInfoBar';
import { useCompareContext } from 'Contexts/compareContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, H5, Tooltip, TooltipContentProps } from 'DesignLibrary/atoms';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { HouseholdMember, ProfileIncentiveQuestionAnswers } from 'Types/entities';
import { checkArraysForSymetricDifference } from 'Utils/helpers';

import { MemberSelectionWrapper } from './styled';
import { MemberSelectionModal } from '../MemberSelectionModal';

export interface MemberSelectionContentProps {
  allHouseholdMembers: HouseholdMember[];
  selectedMemberIds: string[];
  hasEnteredCustomPlan: boolean;
  isLoading: boolean;
  survey: Survey;
  isBuilderClient: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  isIncentiveSurveyLoading: boolean;
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  resultPageIncentiveSurvey: ResultPageIncentiveSurveyData;
  spouse?: Member;
  createUpdateHouseholdAndIncentiveSurvey: () => void;
  submitIncentiveSurveyResponses: (incentiveSurveyResonses) => void;
  setSelectedMemberIds: (memberIds: string[]) => void;
  lockFuturePages: () => void;
  handleSavePregnancyAnswer: (val: string) => void;
  handleSectionChange: (key: 'incentives' | 'pregnancy', isValid: boolean, isComplete: boolean) => void;
  updateActiveMembers: (members: string[]) => void;
  setProfileIncentiveSurvey: (data: ProfileIncentiveQuestionAnswers) => void;
}

export const MemberSelectionContent = ({
  allHouseholdMembers,
  selectedMemberIds,
  hasEnteredCustomPlan,
  isLoading,
  survey,
  incentiveSurvey,
  isBuilderClient,
  isIncentiveSurveyLoading,
  incentiveSurveyAnswers,
  createUpdateHouseholdAndIncentiveSurvey,
  submitIncentiveSurveyResponses,
  setSelectedMemberIds,
  lockFuturePages,
  handleSavePregnancyAnswer,
  handleSectionChange,
  updateActiveMembers,
  setProfileIncentiveSurvey,
  resultPageIncentiveSurvey,
  spouse,
}: MemberSelectionContentProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { resetComparedPlans } = useCompareContext();

  const [currentSelectedMemberIds, setCurrentSelectedMemberIds] = useState(selectedMemberIds);
  const [pregnancyAnswer, setPregnancyAnswer] = useState<string>(survey.plan_child_question);
  const [isSpouseSelected, setIsSpouseSelected] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [didSpouseChange, setDidSpouseChange] = useState(false);
  const [deselectedMemberIds, setDeselectedMemberIds] = useState<string[]>([]);
  const [justSelected, setJustSelected] = useState<boolean>(false);

  const originalIncentiveData = {
    incentiveSurvey,
    incentiveSurveyAnswers,
  };

  useEffect(() => {
    setIsSpouseSelected(
      Boolean(allHouseholdMembers.find((member) => !member.policyholder && !member.dependant)),
    );
  }, []);

  const getMemberType = (member: HouseholdMember) => {
    if (member.policyholder) {
      return <Text field="profile_section.member.policyholder_card_header" />;
    }
    if (member.dependant) {
      return <Text field="profile_section.member.dependent_card_header" />;
    }
    if (member.member_type === MEMBER_TYPE.SPOUSE) {
      return <Text field="profile_section.member.spouse_card_header" />;
    }
    return <Text field="profile_section.member.domestic_partner_card_header" />;
  };

  const handleOnChange = (memberId: string) => {
    if (currentSelectedMemberIds.includes(memberId)) {
      setCurrentSelectedMemberIds(currentSelectedMemberIds.filter((i) => i !== memberId));
      setDeselectedMemberIds([...deselectedMemberIds, memberId]);
      setJustSelected(false);
    } else {
      setCurrentSelectedMemberIds([...currentSelectedMemberIds, memberId]);
      setDeselectedMemberIds(deselectedMemberIds.filter((i) => i !== memberId));
      setJustSelected(true);
    }
  };

  useEffect(() => {
    const isSpouseChanged = getDidSpouseChange();
    setIsSpouseSelected(getIsSpouseSelected());
    updateActiveMembers(currentSelectedMemberIds);
    setDidSpouseChange(isSpouseChanged);
  }, [currentSelectedMemberIds]);

  const updateButtonEnabled =
    currentSelectedMemberIds.length > 0 &&
    checkArraysForSymetricDifference(selectedMemberIds, currentSelectedMemberIds);

  const canBearChild = () =>
    allHouseholdMembers
      .filter((i) => currentSelectedMemberIds.includes(i.external_id))
      .filter(
        (member) =>
          (member.gender === 'female' || member.gender === 'prefer_not_to_answer') && member.age >= 12,
      ).length > 0;

  const olderDependentDisclaimer = () =>
    allHouseholdMembers.filter((member) => member.dependant && member.age >= 26).length > 0;

  const getIsSpouseSelected = () => {
    const spouse = allHouseholdMembers.find((member) => !member.policyholder && !member.dependant);
    if (spouse) {
      return currentSelectedMemberIds.some((i) => i === spouse.external_id);
    }
    return false;
  };

  const getDidSpouseChange = () => {
    const spouse = allHouseholdMembers.find((member) => !member.policyholder && !member.dependant);
    if (spouse) {
      const wasSpousePrevSelected = selectedMemberIds.some((i) => i === spouse.external_id);
      const isSpouseSelected = currentSelectedMemberIds.some((i) => i === spouse.external_id);

      return (wasSpousePrevSelected && !isSpouseSelected) || (!wasSpousePrevSelected && isSpouseSelected);
    }
    return false;
  };

  const openModalOrUpdateMembers = () => {
    if (isBuilderClient) {
      if (canBearChild() || getDidSpouseChange()) {
        setIsOpen(true);
      } else {
        handleModalSubmit();
      }
    } else if (canBearChild()) {
      setIsOpen(true);
    } else {
      handleModalSubmit();
    }
  };

  const handleModalSubmit = () => {
    if (survey.plan_child_question === 'yes' && !canBearChild) {
      handleSavePregnancyAnswer('no');
    } else if (survey.plan_child_question !== pregnancyAnswer) {
      handleSavePregnancyAnswer(pregnancyAnswer);
    }
    setSelectedMemberIds(currentSelectedMemberIds);
    setIsOpen(false);
    lockFuturePages();
    resetComparedPlans();
  };

  const handleCloseModal = () => {
    setProfileIncentiveSurvey(originalIncentiveData);
    setIsOpen(false);
  };

  return (
    <MemberSelectionWrapper>
      <div className="title">
        <H5 weight="normal">
          <Text field="health_section.secondary_content.member_selection_header" />
        </H5>
        <Tooltip
          id="member-select-tooltip"
          label={retrieveContentfulData<TooltipContentProps>(
            'health_section.secondary_content.tooltips.memberSelectionHeader',
          )}
        />
      </div>
      <div className="household-members">
        {allHouseholdMembers.map((member) => (
          <div key={`checkbox-${member.external_id}`}>
            <Checkbox
              sizing="small"
              testId={`checkbox-${member.external_id}`}
              handleChange={() => handleOnChange(member.external_id)}
              checked={currentSelectedMemberIds.includes(member.external_id)}
              disabled={member.policyholder || isLoading || (member.dependant && member.age > 25)}
              textStylingOverride={{
                color: '--text-gray',
                weight: 'normal',
              }}
            >
              {getMemberType(member)} {member.dependant && `(${member.age})`}
            </Checkbox>
          </div>
        ))}
        {olderDependentDisclaimer() && (
          <ContentfulInfoBar
            field="health_section.secondary_content.member_overage_dependent_disclaimer"
            key="health_section.secondary_content.member_overage_dependent_disclaimer.value"
          />
        )}
        {updateButtonEnabled && hasEnteredCustomPlan && (
          <InfoBar
            data-testid="member-selection-custom-plan-warning"
            text={<RichText field="health_section.secondary_content.member_selection_info_bar.text" />}
          />
        )}
      </div>
      <div className="submit">
        <Button
          size="small"
          stretch
          isDisabled={!updateButtonEnabled}
          onClick={() => {
            // - If there are incentive questions, they should be asked
            // - The pregnancy question should also appear if there is a dependent who is female sex-assigned-at-birth and age >= 12.
            if (
              !canBearChild() ||
              justSelected ||
              deselectedMemberIds.length === 0 ||
              incentiveSurvey.length > 0
            ) {
              openModalOrUpdateMembers();
            } else {
              handleModalSubmit();
            }
            setJustSelected(false);
          }}
          isLoading={isLoading}
        >
          <Text field="health_section.secondary_content.member_selection_update_button" />
        </Button>
      </div>
      <MemberSelectionModal
        isOpen={isOpen}
        incentiveSurvey={resultPageIncentiveSurvey.incentiveSurvey}
        hasSpouse={isSpouseSelected}
        isLoading={isLoading}
        isIncentiveSurveyLoading={isIncentiveSurveyLoading}
        incentiveSurveyAnswers={resultPageIncentiveSurvey.incentiveSurveyAnswers}
        pregnancyAnswer={pregnancyAnswer}
        setPregnancyAnswer={setPregnancyAnswer}
        handleModalSubmit={handleModalSubmit}
        createUpdateHouseholdAndIncentiveSurvey={createUpdateHouseholdAndIncentiveSurvey}
        submitIncentiveSurveyResponses={submitIncentiveSurveyResponses}
        handleSectionChange={handleSectionChange}
        canBearChild={canBearChild()}
        didSpouseChange={didSpouseChange}
        closeModal={handleCloseModal}
        spouse={spouse}
      />
    </MemberSelectionWrapper>
  );
};
