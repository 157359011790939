import { Stack } from '@chakra-ui/react';
import React from 'react';

import { LifeBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { H5, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

const supplementalTerm = (suppTerm?: 'Voluntary' | 'Supp' | 'Optional') => {
  switch (suppTerm) {
    case 'Voluntary':
      return <Text field="benefits_section_life.voluntary" />;
    case 'Supp':
      return <Text field="benefits_section_life.supplemental" />;
    case 'Optional':
      return <Text field="benefits_section_life.optional" />;
    default:
      return '';
  }
};

export const lifeDetails = (plan: SupplementalPlan) => {
  const benefits = transformBenefitItems(plan) as LifeBenefits;

  const salaryOrEP = (howDetermined?: 'Salary' | 'EligiblePay') => {
    if (howDetermined === 'EligiblePay') {
      return <Text field="benefits_section_life.eligible_pay" />;
    }

    return <Text field="benefits_section_life.salary" />;
  };

  let basicBenefitSalaryMultiple: JSX.Element | null = null;
  if (benefits.benefits === 'Basic' || benefits.benefits === 'Both') {
    if (benefits.basic_calc_method === 'MultipleOfSalary') {
      const basicBenefitKey = <Text field="benefits_section_life.basic_benefit_amount" />;
      const basicBenefitValue = (
        <Text
          field="benefits_section_life.benefit_is_multiple_of_salary"
          vars={{ multiple: benefits.salary_multiple, salaryOrEP: salaryOrEP(benefits.how_determined) }}
        />
      );
      basicBenefitSalaryMultiple = (
        <HiddenItem className="salary-multiple">
          <Paragraph size="mini" className="key">
            {basicBenefitKey}
          </Paragraph>

          <Paragraph size="mini" className="value">
            {basicBenefitValue}
          </Paragraph>
        </HiddenItem>
      );
    }
  }

  let basicBenefitSalaryLimit: JSX.Element | null = null;
  if (benefits.benefits === 'Basic' || benefits.benefits === 'Both') {
    if (benefits.flat_amount || benefits.max_benefit) {
      const benefitLimitKey = <Text field="benefits_section_life.basic_benefit_limit" />;
      const benefitLimit = benefits.flat_amount || benefits.max_benefit;
      const benefitLimitValue = toDollars(benefitLimit);
      basicBenefitSalaryLimit = (
        <HiddenItem className="basic-limit">
          <Paragraph size="mini" className="key">
            {benefitLimitKey}
          </Paragraph>

          <Paragraph size="mini" className="value">
            {benefitLimitValue}
          </Paragraph>
        </HiddenItem>
      );
    }
  }

  let suppBenefitSalaryMultiple: JSX.Element | null = null;
  if (benefits.benefits !== 'Basic') {
    if (benefits.supplemental_multiple && parseInt(benefits.supplemental_multiple, 10) > 0) {
      const suppBenefitMultipleKey = (
        <Text
          field="benefits_section_life.supplemental_benefit_amount"
          vars={{ suppTerm: supplementalTerm(benefits.supplemental_term) }}
        />
      );
      const suppBenefitMultipleValue = (
        <Text
          field="benefits_section_life.benefit_is_multiple_of_salary"
          vars={{
            multiple: benefits.supplemental_multiple,
            salaryOrEP: salaryOrEP(benefits.how_determined),
          }}
        />
      );
      suppBenefitSalaryMultiple = (
        <HiddenItem className="supp-multiple">
          <Paragraph size="mini" className="key">
            {suppBenefitMultipleKey}
          </Paragraph>

          <Paragraph size="mini" className="value">
            {suppBenefitMultipleValue}
          </Paragraph>
        </HiddenItem>
      );
    }
  }

  let suppBenefitSalaryLimit: JSX.Element | null = null;
  if (benefits.benefits !== 'Basic' && benefits.supplemental_limit) {
    suppBenefitSalaryLimit = (
      <HiddenItem className="supp-multiple">
        <Paragraph size="mini" className="key">
          <Text
            field="benefits_section_life.supplemental_benefit_limit"
            vars={{ suppTerm: supplementalTerm(benefits.supplemental_term) }}
          />
        </Paragraph>

        <Paragraph size="mini" className="value">
          {toDollars(benefits.supplemental_limit)}
        </Paragraph>
      </HiddenItem>
    );
  }

  return (
    <section className="benefits hidden-benefits life-plan">
      <Paragraph as="h6">
        <Text field="benefits_section.plan_card_text.payouts" />
      </Paragraph>

      {basicBenefitSalaryMultiple}
      {basicBenefitSalaryLimit}

      {suppBenefitSalaryMultiple}
      {suppBenefitSalaryLimit}
    </section>
  );
};

export const lifeAdditionalNotes = (plan: SupplementalPlan, hasDependentCoverage: boolean) => {
  const benefits = transformBenefitItems(plan) as LifeBenefits;

  const eoiNote = () => {
    if (benefits.require_eoi !== 'Never') {
      return (
        <Stack>
          <H5>
            <Text field="benefits_section_life.eoi_header" />
          </H5>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            <Text field="benefits_section_life.eoi_body" />
          </Paragraph>
        </Stack>
      );
    }
    return null;
  };

  const dependentCoverageNote = () => {
    if (hasDependentCoverage) {
      return (
        <Stack>
          <H5>
            <Text field="benefits_section_life.dependent_coverage_header" />
          </H5>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            <Text field="benefits_section_life.dependent_coverage_body" />
          </Paragraph>
        </Stack>
      );
    }
    return null;
  };

  return (
    <Stack gap={4}>
      {eoiNote()}
      {dependentCoverageNote()}
    </Stack>
  );
};

export const getLifeTagList = (lifeBenefits: LifeBenefits): JSX.Element[] => {
  const tags: JSX.Element[] = [];

  if (lifeBenefits.benefits === 'Basic' || lifeBenefits.benefits === 'Both') {
    if (lifeBenefits.flat_amount || lifeBenefits.max_benefit) {
      tags.push(<Text field="benefits_section_life.basic_benefit_limit" />);
    }

    if (lifeBenefits.basic_calc_method === 'MultipleOfSalary') {
      tags.push(<Text field="benefits_section_life.basic_benefit_amount" />);
    }
  }

  if (lifeBenefits.benefits === 'Supp' || lifeBenefits.benefits === 'Both') {
    if (lifeBenefits.supplemental_multiple) {
      tags.push(
        <Text
          field="benefits_section_life.supplemental_benefit_amount"
          vars={{ suppTerm: supplementalTerm(lifeBenefits.supplemental_term) }}
        />,
      );
    }
    if (lifeBenefits.supplemental_limit) {
      tags.push(
        <Text
          field="benefits_section_life.supplemental_benefit_limit"
          vars={{ suppTerm: supplementalTerm(lifeBenefits.supplemental_term) }}
        />,
      );
    }
  }

  return tags;
};
