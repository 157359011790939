import React, { ChangeEvent, useEffect, useRef } from 'react';

import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import { useTextContext } from 'Contexts/textContext';
import { Button, H1, Icon, Paragraph } from 'DesignLibrary/atoms';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { Warning, FlexRow } from './styled';
import { PREMIUM_PERIOD } from '../../../../constants';
import { CheckWrapper, InputWrapper, QuestionWrapper } from '../styled';

const isHsaEligibleTargetName = 'isHsaEligible';
const isHraEligibleTargetName = 'isHraEligible';

interface PlanEntryDetailsProps {
  plan: EnteredPlan;
  savePlan: (plan: EnteredPlan) => void;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  isFamily: boolean;
}

type TitleAndText = { title: string; text: string };

const PlanEntryDetails: (props: PlanEntryDetailsProps) => JSX.Element = ({
  plan,
  savePlan,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  isFamily,
}) => {
  const {
    premiumAmount,
    premiumPeriod,
    isDeductibleComprehensive,
    isOopMaxComprehensive,
    deductibleAmount,
    drugDeductibleAmount,
    healthDeductibleAmount,
    oopMaxAmount,
    drugOopMaxAmount,
    healthOopMaxAmount,
    isHsaEligible,
    employerHsaContribution,
    isHraEligible,
    employerHraContribution,
  } = plan;

  const { retrieveContentfulData } = useTextContext();
  // Initializing ref with null causes type-checking errors when we try to access the refs because they are possibly undefined
  const deductibleRef = useRef<HTMLInputElement>(document.createElement('input'));
  const oopMaxRef = useRef<HTMLInputElement>(document.createElement('input'));
  const drugDeductibleAmountRef = useRef<HTMLInputElement>(document.createElement('input'));
  const healthDeductibleAmountRef = useRef<HTMLInputElement>(document.createElement('input'));
  const drugOopMaxAmountRef = useRef<HTMLInputElement>(document.createElement('input'));
  const healthOopMaxAmountRef = useRef<HTMLInputElement>(document.createElement('input'));

  useEffect(() => {
    // HSA plans cannot have deductibles below $1400 for an individual and $2800 for a family
    // HSA plans cannot have maximum OOPs greater than $7,000 for individual coverage and $14,000 for family coverage
    validateDeductible(deductibleRef.current);
    validateOopMax(oopMaxRef.current);
    validateHsaPlan();
    validateDeductibleAndOopMax();
  }, [
    isHsaEligible,
    isDeductibleComprehensive,
    isOopMaxComprehensive,
    deductibleAmount,
    oopMaxAmount,
    healthDeductibleAmount,
    drugDeductibleAmount,
    healthOopMaxAmount,
    drugOopMaxAmount,
  ]);

  const { monthly } = retrieveContentfulData<Record<string, string>>(
    'spousal_plan_comparison.button_text.premiumPeriod.periodNames',
  );

  const premiumPeriodNamesMap: Record<PREMIUM_PERIOD, string> = {
    [PREMIUM_PERIOD.MONTHLY]: monthly,
  };
  const toolTips = {
    familyThreshold: retrieveContentfulData<TitleAndText>('tool_tips.family_threshold'),
    premium: retrieveContentfulData<TitleAndText>('tool_tips.premium'),
    deductible: retrieveContentfulData<TitleAndText>('tool_tips.deductible'),
    oopMax: retrieveContentfulData<TitleAndText>('tool_tips.out_of_pocket_max'),
    hsaEligible: retrieveContentfulData<TitleAndText>('tool_tips.hsa'),
    hraEligible: retrieveContentfulData<TitleAndText>('tool_tips.hra'),
  };

  interface ValidationMessages {
    deductibleValidationError: string;
    splitDeductibleValidationError: string;
    splitDeductibleExceedsOopMax: string;
    deductibleExceedsSplitOopMax: string;
    oopMaxValidationError: string;
    splitOopMaxValidationError: string;
    deductibleExceedsOopMax: string;
    healthDeductibleExceedsHealthOopMax: string;
    drugDeductibleExceedsDrugOopMax: string;
  }
  const validationMessages = retrieveContentfulData<ValidationMessages>(
    'spousal_plan_comparison.validation_messages',
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = e.target.value.replace(/,/g, ''); // Remove commas from value
    const parsed: number | '' = newValue === '' ? '' : parseInt(newValue, 10);

    if (Number.isInteger(parsed) || newValue === '') {
      const newField = e.target.name;
      const maxAllowed = parseInt(e.target.max, 10) || Infinity;

      if (parsed === '' || (parsed <= maxAllowed && parsed >= 0)) {
        const newPlan = { ...plan };

        // This is not terribly safe, and TS is only allowing it because we have `noImplicitAny`
        // set to false in our tsconfig.json.  Without that, we get
        //    TS7053: Element implicitly has an 'any' type because expression of type 'string' can't
        //    be used to index type 'EnteredPlan'. No index signature with a parameter of type
        //    'string' was found on type 'EnteredPlan'.
        newPlan[newField] = parsed;

        savePlan(newPlan);
      } else {
        savePlan(plan);
      }
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    const newField = e.target.name;

    const newPlan = { ...plan } as Record<string, unknown>;
    newPlan[newField] = newValue;

    // Only allow HSA selection if HRA is not selected
    if (e.target.name === isHsaEligibleTargetName && !isHraEligible) {
      // If HSA is selected reset 'employerHraContribution'
      if (newValue) newPlan.employerHraContribution = 0;
    }

    // Only allow HRA selection if HSA is not selected
    if (e.target.name === isHraEligibleTargetName && !isHsaEligible) {
      // If HRA is selected reset 'employerHsaContribution'
      if (newValue) newPlan.employerHsaContribution = 0;
    }

    savePlan(newPlan as unknown as EnteredPlan);
  };

  const handleDeductibleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e);

  const handleDeductibleTypeChange = (newDeductibleComprehensive: boolean) => {
    const newPlan = { ...plan };
    newPlan.isDeductibleComprehensive = newDeductibleComprehensive;
    savePlan(newPlan);
  };

  const validateDeductible = (target: HTMLInputElement) => {
    if (target && target.value) {
      const newValue = target.value.replace(/,/g, '') || '';
      const deductibleLimit = !isFamily ? 1400 : 2800;
      if (parseInt(newValue, 10) < deductibleLimit && isHsaEligible) {
        const formattedValue = deductibleLimit.toLocaleString();
        target.setCustomValidity(validationMessages.deductibleValidationError.replace('{x}', formattedValue));
      } else target.setCustomValidity('');
    }
  };

  const handleOopMaxChange = (e: ChangeEvent<HTMLInputElement>) => handleInputChange(e);

  const validateDeductibleAndOopMax = () => {
    if (!isDeductibleComprehensive && !isOopMaxComprehensive) {
      // If both deductible and OOP max are split perform 1:1 comparison of healthDeductible:healthOopMax & drugDeductible:drugOopMax
      const healthValid =
        healthOopMaxAmount !== '' &&
        healthDeductibleAmount !== '' &&
        healthOopMaxAmount >= healthDeductibleAmount;
      const drugValid =
        drugOopMaxAmount !== '' && drugDeductibleAmount !== '' && drugOopMaxAmount >= drugDeductibleAmount;
      setSplitDeductibleValidity(healthValid, drugValid);
    } else {
      // Else if values are split sum them and then compare
      let deductibleSum;
      if (isDeductibleComprehensive) {
        deductibleSum = deductibleAmount;
      } else if (healthDeductibleAmount !== '' && drugDeductibleAmount !== '') {
        deductibleSum = healthDeductibleAmount + drugDeductibleAmount;
      } else {
        deductibleSum = null;
      }

      let oopMaxSum;
      if (isOopMaxComprehensive) {
        oopMaxSum = oopMaxAmount;
      } else if (healthOopMaxAmount !== '' && drugOopMaxAmount !== '') {
        oopMaxSum = healthOopMaxAmount + drugOopMaxAmount;
      } else {
        oopMaxSum = null;
      }
      const valid = deductibleSum !== null && oopMaxSum !== null && oopMaxSum >= deductibleSum;
      setDeductibleValidity(valid);
    }
  };

  // Validates comprehensiveDeductible:comprehensiveOopMax && splitDeductible:comprehensiveOopMax && comprehensiveDeductible:splitOopMax
  const setDeductibleValidity = (valid: boolean) => {
    if (deductibleRef && deductibleRef.current) {
      const { deductibleExceedsOopMax, splitDeductibleExceedsOopMax, deductibleExceedsSplitOopMax } =
        validationMessages;
      // We only need to set error messages on the comprehensive or split deductible fields.  We don't need to set errors on OOP Max fields because they never get displayed
      // Handle comprehensive deductible & comprehensive OOP Max
      if (
        isDeductibleComprehensive &&
        isOopMaxComprehensive &&
        deductibleRef.current.validationMessage === ''
      ) {
        // Prevent over-writing existing error message
        deductibleRef.current.setCustomValidity(valid ? '' : deductibleExceedsOopMax);
      }
      if (
        !isDeductibleComprehensive &&
        isOopMaxComprehensive &&
        deductibleRef.current.validationMessage === ''
      ) {
        // Prevent over-writing existing error message
        deductibleRef.current.setCustomValidity(valid ? '' : splitDeductibleExceedsOopMax);
      }
      if (
        isDeductibleComprehensive &&
        !isOopMaxComprehensive &&
        deductibleRef.current.validationMessage === ''
      ) {
        // Prevent over-writing existing error message
        deductibleRef.current.setCustomValidity(valid ? '' : deductibleExceedsSplitOopMax);
      }
      // Handle split deductible & comprehensive OOP Max (because we are handling split:comprensive both fields recieve the error message)
      if (
        !isDeductibleComprehensive &&
        healthDeductibleAmountRef.current.validationMessage === '' &&
        drugDeductibleAmountRef.current.validationMessage === ''
      ) {
        healthDeductibleAmountRef.current.setCustomValidity(valid ? '' : splitDeductibleExceedsOopMax);
        drugDeductibleAmountRef.current.setCustomValidity(valid ? '' : splitDeductibleExceedsOopMax);
      }
    }
  };

  const setSplitDeductibleValidity = (isHealthValid: boolean, isDrugValid: boolean) => {
    const { healthDeductibleExceedsHealthOopMax, drugDeductibleExceedsDrugOopMax } = validationMessages;
    if (
      healthDeductibleAmountRef &&
      healthDeductibleAmountRef.current &&
      healthDeductibleAmountRef.current.validationMessage === ''
    ) {
      // Prevent over-writing existing error message
      healthDeductibleAmountRef.current.setCustomValidity(
        isHealthValid ? '' : healthDeductibleExceedsHealthOopMax,
      );
    }
    if (
      drugDeductibleAmountRef &&
      drugDeductibleAmountRef.current &&
      drugDeductibleAmountRef.current.validationMessage === ''
    ) {
      drugDeductibleAmountRef.current.setCustomValidity(isDrugValid ? '' : drugDeductibleExceedsDrugOopMax);
    }
  };

  const handleOopMaxTypeChange = (newOopMaxComprehensive: boolean) => {
    const newPlan = { ...plan };
    newPlan.isOopMaxComprehensive = newOopMaxComprehensive;
    savePlan(newPlan);
  };

  const validateOopMax = (target: HTMLInputElement) => {
    if (target && target.value) {
      const newValue = target.value.replace(/,/g, '');
      const oopMaxLimit = !isFamily ? 7000 : 14000;
      if (parseInt(newValue, 10) > oopMaxLimit && isHsaEligible) {
        const formattedValue = oopMaxLimit.toLocaleString();
        target.setCustomValidity(validationMessages.oopMaxValidationError.replace('{x}', formattedValue));
      } else target.setCustomValidity('');
    }
  };

  const validateHsaPlan = () => {
    // Per: https://picwell.slack.com/archives/CBBK0126Q/p1623348325046500
    // we do not allow an HSA plan to be input when deductibles or OOP Max are split (not comprehensive)
    if (
      drugDeductibleAmountRef &&
      drugDeductibleAmountRef.current &&
      isHsaEligible &&
      !isDeductibleComprehensive
    ) {
      drugDeductibleAmountRef.current.setCustomValidity(validationMessages.splitDeductibleValidationError);
      healthDeductibleAmountRef.current.setCustomValidity(validationMessages.splitDeductibleValidationError);
    } else if (
      drugOopMaxAmountRef &&
      drugOopMaxAmountRef.current &&
      isHsaEligible &&
      !isOopMaxComprehensive
    ) {
      drugOopMaxAmountRef.current.setCustomValidity(validationMessages.splitOopMaxValidationError);
      healthOopMaxAmountRef.current.setCustomValidity(validationMessages.splitOopMaxValidationError);
    } else {
      if (drugDeductibleAmountRef && drugDeductibleAmountRef.current)
        drugDeductibleAmountRef.current.setCustomValidity('');
      if (healthDeductibleAmountRef && healthDeductibleAmountRef.current)
        healthDeductibleAmountRef.current.setCustomValidity('');
      if (drugOopMaxAmountRef && drugOopMaxAmountRef.current)
        drugOopMaxAmountRef.current.setCustomValidity('');
      if (healthOopMaxAmountRef && healthOopMaxAmountRef.current)
        healthOopMaxAmountRef.current.setCustomValidity('');
    }
  };

  const premiumPeriodQuestionLabelId = 'premium-amount-question';

  return (
    <>
      <H1>
        <Text field="spousal_plan_comparison.details_title" />
      </H1>
      <Warning>
        <Icon type="Warning" color="--text-black" />
        <span>
          <RichText
            field="spousal_plan_comparison.threshold_warning"
            toolTips={[
              {
                title: toolTips.familyThreshold.title,
                text: toolTips.familyThreshold.text,
              },
            ]}
          />
        </span>
      </Warning>
      <QuestionWrapper id="premium-amount-question">
        <RichText
          field="spousal_plan_comparison.premium_question"
          toolTips={[
            {
              title: toolTips.premium.title,
              text: toolTips.premium.text.replace('{x}', premiumPeriodNamesMap[premiumPeriod].toLowerCase()),
            },
          ]}
        />
        <InputWrapper>
          <TextInput
            labelId={premiumPeriodQuestionLabelId}
            name="premiumAmount"
            testId="premiumAmount"
            value={premiumAmount ? premiumAmount.toString() : ''}
            handleChange={(e) => handleInputChange(e)}
            inputType="currency"
            required
            max={99999}
          />
        </InputWrapper>
      </QuestionWrapper>
      <QuestionWrapper id="deductible-amount-question">
        <RichText
          field="spousal_plan_comparison.deductible_question"
          toolTips={[
            {
              title: toolTips.deductible.title,
              text: toolTips.deductible.text,
            },
          ]}
        />
        {isDeductibleComprehensive && (
          <FlexRow>
            <TextInput
              labelId="deductible-amount-question"
              name="deductibleAmount"
              testId="deductibleAmount"
              inputType="currency"
              inputMode="text"
              value={deductibleAmount ? deductibleAmount.toString() : ''}
              handleChange={handleDeductibleChange}
              max={99999}
              ref={deductibleRef}
            />
            <Button
              className="toggle-text"
              data-testid="toggleDeductible"
              onClick={() => handleDeductibleTypeChange(false)}
              buttonType="link"
            >
              <u>
                <Text field="spousal_plan_comparison.deductible_subfields.expand" />
              </u>
            </Button>
          </FlexRow>
        )}
        {!isDeductibleComprehensive && (
          <>
            <Button
              className="toggle-text"
              buttonType="link"
              onClick={() => handleDeductibleTypeChange(true)}
            >
              <u>
                <Text field="spousal_plan_comparison.deductible_subfields.contract" />
              </u>
            </Button>
            <InputWrapper>
              <Paragraph id="drug-deductible-amount-label">
                <Text field="spousal_plan_comparison.deductible_subfields.drugText" />
              </Paragraph>
              <TextInput
                labelId="drug-deductible-amount-label"
                name="drugDeductibleAmount"
                testId="drugDeductibleAmount"
                inputType="currency"
                inputMode="text"
                value={drugDeductibleAmount ? drugDeductibleAmount.toString() : ''}
                handleChange={(e) => handleInputChange(e)}
                required={!isDeductibleComprehensive}
                max={99999}
                ref={drugDeductibleAmountRef}
              />
            </InputWrapper>
            <InputWrapper>
              <Paragraph id="health-deductible-amount-label">
                <Text field="spousal_plan_comparison.deductible_subfields.healthText" />
              </Paragraph>
              <TextInput
                labelId="health-deductible-amount-label"
                name="healthDeductibleAmount"
                testId="healthDeductibleAmount"
                inputType="currency"
                inputMode="text"
                value={healthDeductibleAmount ? healthDeductibleAmount.toString() : ''}
                handleChange={(e) => handleInputChange(e)}
                required={!isDeductibleComprehensive}
                max={99999}
                ref={healthDeductibleAmountRef}
              />
            </InputWrapper>
          </>
        )}
      </QuestionWrapper>
      <QuestionWrapper id="oop-max-amount-question">
        <RichText
          field="spousal_plan_comparison.oop_max_question"
          toolTips={[
            {
              title: toolTips.oopMax.title,
              text: toolTips.oopMax.text,
            },
          ]}
        />
        {isOopMaxComprehensive && (
          <FlexRow>
            <TextInput
              labelId="oop-max-amount-question"
              name="oopMaxAmount"
              testId="oopMaxAmount"
              inputType="currency"
              inputMode="text"
              value={oopMaxAmount ? oopMaxAmount.toString() : ''}
              handleChange={handleOopMaxChange}
              required={isOopMaxComprehensive}
              max={99999}
              ref={oopMaxRef}
            />
            <Button
              className="toggle-text"
              data-testid="toggleOopMax"
              onClick={() => {
                handleOopMaxTypeChange(false);
              }}
              buttonType="link"
            >
              <u>
                <Text field="spousal_plan_comparison.oop_max_fields.expand" />
              </u>
            </Button>
          </FlexRow>
        )}

        {!isOopMaxComprehensive && (
          <>
            <Button
              className="toggle-text"
              data-testid="toggleOopMaxClose"
              onClick={() => {
                handleOopMaxTypeChange(true);
              }}
              buttonType="link"
            >
              <u>
                <Text field="spousal_plan_comparison.oop_max_fields.contract" />
              </u>
            </Button>
            <InputWrapper>
              <Paragraph id="drug-oop-max-amount-label">
                <Text field="spousal_plan_comparison.oop_max_fields.drugText" />
              </Paragraph>
              <TextInput
                labelId="drug-oop-max-amount-label"
                name="drugOopMaxAmount"
                testId="drugOopMaxAmount"
                inputType="currency"
                inputMode="text"
                value={drugOopMaxAmount ? drugOopMaxAmount.toString() : ''}
                handleChange={(e) => handleInputChange(e)}
                required={!isOopMaxComprehensive}
                max={99999}
                ref={drugOopMaxAmountRef}
              />
            </InputWrapper>
            <InputWrapper>
              <Paragraph id="health-oop-max-amount-label">
                <Text field="spousal_plan_comparison.oop_max_fields.healthText" />
              </Paragraph>
              <TextInput
                labelId="health-oop-max-amount-label"
                name="healthOopMaxAmount"
                testId="healthOopMaxAmount"
                inputType="currency"
                inputMode="text"
                value={healthOopMaxAmount ? healthOopMaxAmount.toString() : ''}
                handleChange={(e) => handleInputChange(e)}
                required={!isOopMaxComprehensive}
                max={99999}
                ref={healthOopMaxAmountRef}
              />
            </InputWrapper>
          </>
        )}
      </QuestionWrapper>
      <QuestionWrapper style={{ marginBottom: 0 }}>
        <CheckWrapper>
          <Checkbox
            name={isHsaEligibleTargetName}
            testId={isHsaEligibleTargetName}
            checked={isHsaEligible}
            disabled={isHraEligible}
            handleChange={handleCheckboxChange}
          >
            <span className="checkbox-label-spacing">
              <RichText
                field="spousal_plan_comparison.hsa_eligible_question"
                toolTips={[
                  {
                    title: toolTips.hsaEligible.title,
                    text: toolTips.hsaEligible.text
                      .replace('{x}', `$${individualHsaContributionLimit}`)
                      .replace('{x}', `$${familyHsaContributionLimit}`),
                  },
                ]}
              />
            </span>
          </Checkbox>
        </CheckWrapper>
        {isHsaEligible && (
          <>
            <Paragraph id="employer-hsa-contribution-label">
              <Text field="spousal_plan_comparison.hsa_contribution_question.question" />
            </Paragraph>
            <TextInput
              labelId="employer-hsa-contribution-label"
              name="employerHsaContribution"
              inputType="currency"
              inputMode="text"
              value={employerHsaContribution ? employerHsaContribution.toString() : ''}
              handleChange={(e) => handleInputChange(e)}
              required={isHsaEligible}
              max={99999}
            />
          </>
        )}
      </QuestionWrapper>
      <QuestionWrapper>
        <CheckWrapper>
          <Checkbox
            name={isHraEligibleTargetName}
            testId={isHraEligibleTargetName}
            checked={isHraEligible}
            disabled={isHsaEligible}
            handleChange={handleCheckboxChange}
          >
            <span className="checkbox-label-spacing">
              <RichText
                field="spousal_plan_comparison.hra_eligible_question"
                toolTips={[
                  {
                    title: toolTips.hraEligible.title,
                    text: toolTips.hraEligible.text,
                  },
                ]}
              />
            </span>
          </Checkbox>
        </CheckWrapper>
        {isHraEligible && (
          <>
            <Paragraph id="employer-hra-contribution-label">
              <Text field="spousal_plan_comparison.hra_contribution_question.question" />
            </Paragraph>
            <TextInput
              labelId="employer-hra-contribution-label"
              name="employerHraContribution"
              inputType="currency"
              inputMode="text"
              value={employerHraContribution ? employerHraContribution.toString() : ''}
              handleChange={(e) => handleInputChange(e)}
              required={isHraEligible}
              max={99999}
            />
          </>
        )}
      </QuestionWrapper>
    </>
  );
};

export default PlanEntryDetails;
