import { Box, Stack } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { TextField } from 'Containers/App/types';
import { getNonSelectableBenefits } from 'Containers/OverviewPage/constants';
import { MEMBER_TYPE } from 'Containers/ProfilePage/types';
import { CoverageDisclaimer } from 'Containers/ViewBenefitPage/BenefitPlans/CoverageDisclaimer';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { ContentfulTextNoteType } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, Paragraph, Spinner } from 'DesignLibrary/atoms';
import { IconBlock } from 'DesignLibrary/molecules/IconBlock';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';
import { DefaultCoveredMembers, HouseholdMember, SupplementalPlan } from 'Types/entities';
import { AriaHiddenAlert } from 'Utils/accessibility';
import { GetSupplementalPlansResponse } from 'Utils/apiTypes';

import { Bullet, LoadingCard, PlansContainer } from './styled';
import { CONTENT_MAP } from '../constants';
import { CoverageOptions } from '../CoverageOptions';
import ExpandablePlanCard from '../ExpandablePlanCard';
import { HospitalIndemnityBenefits, InsuranceType } from '../types';

export interface BenefitPlansProps {
  client: string;
  employerName: string;
  plans: SupplementalPlan[] | null;
  contentfulSupplementalPlans: Record<string, ContentfulSupplementalPlan> | Record<string, never>;
  insuranceType: InsuranceType;
  selectedPlan: SupplementalPlan | null;
  planYear: number;
  coveredMembers: DefaultCoveredMembers;
  isLoading: boolean;
  showFbsCardDetails: boolean;
  supplementalPlansFullHousehold: GetSupplementalPlansResponse | Record<string, never>;
  autoEnrollCount: number;
  isAutoEnrollAvailable: boolean;
  builderCustomerKey: string | null;
  choosePlan: (plan: SupplementalPlan) => void;
  removePlan: (insuranceType: InsuranceType) => void;
  toggleMemberSelect: (memberId: string) => void;
  selectedHospitalIndemnityPlan?: SupplementalPlan | null;
  householdMembers: HouseholdMember[];
}

export const BenefitPlans = ({
  client,
  employerName,
  plans,
  contentfulSupplementalPlans,
  insuranceType,
  selectedPlan,
  planYear,
  coveredMembers,
  isLoading,
  showFbsCardDetails,
  supplementalPlansFullHousehold,
  autoEnrollCount,
  isAutoEnrollAvailable,
  builderCustomerKey,
  choosePlan,
  removePlan,
  toggleMemberSelect,
  selectedHospitalIndemnityPlan,
  householdMembers,
}: BenefitPlansProps) => {
  const { retrieveContentfulData } = useTextContext();
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);

  const accessibilityContent = retrieveContentfulData<Record<string, string>>(
    'health_section.secondary_content.accessibility',
  );

  useEffect(() => {
    if (isLoading) {
      setHiddenAlert(accessibilityContent.loading_bar_alert);
    } else {
      setHiddenAlert(accessibilityContent.loading_bar_complete_alert);
    }
  }, [isLoading]);

  const content = CONTENT_MAP;

  const benefitTitle = retrieveContentfulData<string>(`${content[insuranceType].field}.card_title`);
  const textNotes = retrieveContentfulData<ContentfulTextNoteType[]>(
    'benefits_section_additional.text_notes',
  );

  const members = coveredMembers && Object.entries(coveredMembers[insuranceType]);

  const addCarrier = supplementalPlansFullHousehold.life?.plans[0]?.carrier_name || '';

  const benefitsWithoutCoverageOptions = ['pet', 'legal', '401k', '403b', 'retirement'];

  const hasPlanWithDependentCoverage =
    plans?.some((plan) =>
      plan.eligible_members.some((memberId) =>
        householdMembers.some(
          (member) => member.external_id === memberId && member.member_type !== MEMBER_TYPE.POLICYHOLDER,
        ),
      ),
    ) || false;

  const showCoverageOptions =
    members.length > 1 &&
    hasPlanWithDependentCoverage &&
    !benefitsWithoutCoverageOptions.includes(insuranceType);

  const selectablePlanCount = plans ? plans.length - autoEnrollCount : 0;
  const nonSelectableBenefits = getNonSelectableBenefits(client);
  const isNonSelectableBenefit = nonSelectableBenefits.includes(insuranceType);
  const showBenefitPlans =
    !isAutoEnrollAvailable || (isAutoEnrollAvailable && (isLoading || selectablePlanCount > 0));

  const renderPlans = (autoEnrolled: boolean) =>
    plans &&
    plans
      .sort((a, b) => {
        // Sort by metadata.sort_order if it exists in response
        if (a.metadata && b.metadata) {
          return a.metadata.sort_order - b.metadata.sort_order;
        }

        return 0;
      })
      .filter((plan) => !!plan.auto_enrolled === autoEnrolled)
      .map((plan, idx) => (
        <ExpandablePlanCard
          key={plan.external_id}
          idx={idx}
          plan={plan}
          contentfulSupplementalPlan={contentfulSupplementalPlans?.[plan.external_id]}
          isSelected={isEqual(selectedPlan, plan)}
          selectPlan={(plan) => choosePlan(plan)}
          coveredMembers={coveredMembers[insuranceType]}
          selectablePlanCount={selectablePlanCount}
          removePlan={() => removePlan(insuranceType)}
          insuranceType={insuranceType}
          isNonSelectableBenefit={isNonSelectableBenefit}
          showFbsCardDetails={showFbsCardDetails}
          planWithFullHouseholdSelected={
            supplementalPlansFullHousehold[insuranceType]?.plans.find(
              (fullHouseholdPlan) => fullHouseholdPlan.external_id === plan.external_id,
            ) || {}
          }
          addCarrier={addCarrier}
          employerName={employerName}
          builderCustomerKey={builderCustomerKey}
          selectedHospitalIndemnityPlan={selectedHospitalIndemnityPlan}
          householdMembers={householdMembers}
        />
      ));

  const renderCoverageDisclaimer = () => {
    // Users can only be eligible for one HI plan for now, so plans[0] is fine
    const hospital_plan = plans?.filter((plan) => plan.plan_type === 'hospital')[0];

    if (insuranceType === 'hospital' && hospital_plan && hospital_plan.benefits.items.length > 0) {
      // Only want to render the CoverageDisclaimer component for Builder-configured HI modules with benefits data
      // To avoid incorrectly rendering the "none" coverage disclaimer for text-only custom HI modules

      const benefitItems = transformBenefitItems(plans[0]) as HospitalIndemnityBenefits;
      const displayName = retrieveContentfulData<string>('benefits_section_hospital_indemnity.display_name');

      return (
        <CoverageDisclaimer
          planTypeName={displayName}
          maxChildAge={benefitItems.max_age_children}
          availableToSpouse={!!benefitItems.available_to_spouse}
          availableToChildren={!!benefitItems.available_to_children}
          availableToPartner={!!benefitItems.available_to_partner}
        />
      );
    }

    const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
      'benefits_section_additional.supplemental_plans',
      [],
    );

    // Find the Contentful supplemental plan entry that matches the external_id of any plan for the current insurance type
    const planIds =
      supplementalPlansFullHousehold[insuranceType]?.plans.map((plan) => plan.external_id) || [];
    const coverageDisclaimerIdx = contentfulSupplementalPlans.findIndex((i) => planIds.includes(i.plan_id));

    if (coverageDisclaimerIdx >= 0) {
      const coverageDisclaimer = contentfulSupplementalPlans[coverageDisclaimerIdx]
        .coverage_disclaimer as TextField;

      if (!coverageDisclaimer) {
        return null;
      }
      return (
        <Box mb={4}>
          <InfoBar
            color="yellow"
            icon="Warning"
            className="coverage-disclaimer"
            text={
              <Text
                field={
                  `benefits_section_additional.supplemental_plans[${coverageDisclaimerIdx}].coverage_disclaimer` as TextField
                }
              />
            }
          />
        </Box>
      );
    }
    return null;
  };

  const renderTextNote = () => {
    const matchingTextNotes: ContentfulTextNoteType[] = (textNotes || []).filter(
      (n) => n.supplemental_plan_type === insuranceType && (n.plan_year || '') === planYear?.toString(),
    );
    if (matchingTextNotes.length) {
      return <ContentfulTextNote note={matchingTextNotes[0]} />;
    }

    return null;
  };

  return (
    <PlansContainer>
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>

      {isAutoEnrollAvailable && renderPlans(true)}

      {showBenefitPlans && (
        <>
          {showCoverageOptions && (
            <section className="coverage-options plan-section">
              <Bullet>
                <IconBlock icon="Users" shade={100} color="blue" />
                <Paragraph role="heading" aria-level={2} color="--text-black" id="coverage-options-label">
                  {isAutoEnrollAvailable ? (
                    <Text field="benefits_section_additional.coverage_options_auto_enrolled_label" />
                  ) : (
                    <Text
                      field="benefits_section_additional.coverage_options_label"
                      vars={{ x: benefitTitle.toLowerCase() }}
                    />
                  )}
                </Paragraph>
              </Bullet>

              {renderCoverageDisclaimer()}

              {coveredMembers[insuranceType] && insuranceType !== 'pet' && (
                <CoverageOptions
                  coveredMembers={coveredMembers[insuranceType]}
                  isLoading={isLoading}
                  insuranceType={insuranceType}
                  handleMemberSelect={(memberId) => toggleMemberSelect(memberId)}
                />
              )}
            </section>
          )}

          <section className="plan-wrapper plan-section">
            {!isNonSelectableBenefit && (isAutoEnrollAvailable || hasPlanWithDependentCoverage) && (
              <Bullet>
                <IconBlock icon="Plus" shade={100} color="blue" />
                <Paragraph role="heading" aria-level={2} color="--text-black">
                  {isAutoEnrollAvailable && (
                    <Text
                      field="benefits_section_additional.fbs_plan_auto_enrolled_label"
                      vars={{ x: benefitTitle.toLowerCase() }}
                    />
                  )}
                  {!isAutoEnrollAvailable && hasPlanWithDependentCoverage && (
                    <Text
                      field="benefits_section_additional.fbs_plan_label"
                      vars={{ x: benefitTitle.toLowerCase() }}
                    />
                  )}
                </Paragraph>
              </Bullet>
            )}
            <Stack gap={4}>
              {renderTextNote()}

              <FadeUp isOpen={isLoading}>
                <LoadingCard>
                  <Spinner color="--border-gray" />
                </LoadingCard>
              </FadeUp>
              <FadeUp isOpen={!isLoading}>
                <div className="plan-list">{renderPlans(false)}</div>
              </FadeUp>
            </Stack>
          </section>
        </>
      )}
    </PlansContainer>
  );
};
