import { useDisclosure } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { chooseProduct } from 'Containers/App/actions';
import {
  makeGetEnabledProducts,
  makeGetJvpHasPublicationWithFlag,
  makeSelectConfigField,
  makeSelectGlobalField,
} from 'Containers/App/selectors';
import { AlexProducts, EnabledProducts, JVPState } from 'Containers/App/types';
import { setHouseholdExternalId } from 'Containers/CommercialRoutes/actions';
import { makeSelectProfileField } from 'Containers/ProfilePage/selectors';
import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { useTextContext } from 'Contexts/textContext';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { JVPUserWithDependents } from 'Utils/apiTypes';
import { sendInsightsEvent } from 'Utils/insights';
import { INTRO_PATH, PROFILE_PATH } from 'Utils/urls';

import ClientDisclaimer from './ClientDisclaimer';
import { ConstructionPage } from './ConstructionPage';
import { Footer } from './Footer';
import { Hero } from './Hero';
import { MedicareCallout } from './MedicareCallout';
import { OpenEnrollmentBar } from './OpenEnrollmentBar';
import { ProductSelectModal } from './ProductSelectModal';

interface UnifiedLandingPageStateProps {
  client: string;
  isUnderConstruction: boolean;
  products: EnabledProducts;
  bcUrlHash: string;
  isLoading: boolean;
  forceAlexID: boolean;
  clientSurveyIsLoading: boolean;
  jvp: JVPState;
  isAlexIdEnabled: boolean;
  error: Error | null;
  clientSurveyError: Error | null;
  showLanguageToggle: boolean;
  jvpUserData: JVPUserWithDependents | null;
}

interface UnifiedLandingPageDispatchProps {
  editHouseholdExternalId: (externalId: string) => void;
  next: (path: string) => void;
  chooseProduct: (product: AlexProducts) => void;
}

export type UnifiedLandingPageProps = UnifiedLandingPageStateProps & UnifiedLandingPageDispatchProps;

export const UnifiedLandingPage = ({
  isUnderConstruction,
  client,
  products,
  isLoading,
  clientSurveyIsLoading,
  forceAlexID,
  jvp,
  isAlexIdEnabled,
  error,
  clientSurveyError,
  bcUrlHash,
  editHouseholdExternalId,
  next,
  chooseProduct,
  showLanguageToggle,
  jvpUserData,
}: UnifiedLandingPageProps) => {
  const { isOpen, onOpen, onClose, getButtonProps, getDisclosureProps } = useDisclosure();
  /**
   * The following is a hacky way of working around an issue with Chakra UI:
   * aria-expanded is always set to true, even when the modal is not open
   * this fix can be removed when Chakra and React are updated to the next major version
   */
  const modalButtonAccessibilityProps = getButtonProps();
  modalButtonAccessibilityProps['aria-expanded'] = isOpen;

  const modalTriggerButtonRef = useRef();

  const [isNextClicked, setIsNextClicked] = useState(false);

  const isDevMode = localStorage.getItem('dev');
  const isOnGoNow = window.location.hostname.includes('gonow');
  const { retrieveContentfulData } = useTextContext();

  const hideOEBar = retrieveContentfulData<boolean>('unified_landing_page.hide_open_enrollment_bar', false);

  const ANIMATION_TIMING = !isDevMode ? 1250 : 0;

  const handleNext = () => {
    sendInsightsEvent(null, 'get_started');

    if (isOnGoNow) {
      goToNext(PROFILE_PATH);
    } else if (bcUrlHash && products.bc) {
      // User provided hash on URL, choose BC for them and skip modal
      chooseProduct('bc');
      goToNext(INTRO_PATH);
    } else if (products.bc && products.go) {
      onOpen();
    } else if (products.bc) {
      goToNext(INTRO_PATH);
    } else {
      goToNext(PROFILE_PATH);
    }
  };

  const goToNext = (path: string) => {
    setIsNextClicked(true);
    // Get external_id stored in local storage by SSO and fill it in policyholder if it exists and is a valid UUID
    // todo is this really specific to sso or no?
    const externalId = localStorage.getItem('external_id');
    if (externalId) editHouseholdExternalId(externalId);

    setTimeout(() => {
      next(path);
    }, ANIMATION_TIMING);
  };

  useEffect(() => {
    if (error) {
      throw error;
    }
    if (clientSurveyError) {
      throw clientSurveyError;
    }
  }, [error, clientSurveyError]);

  // Render Construction Page if flag is true
  if (isUnderConstruction) {
    return <ConstructionPage />;
  }

  const shouldUseModal = products.bc && products.go && !isOnGoNow;

  return (
    <>
      <PageLayout bg="--primary-white" page="landing">
        <ContentfulInfoBars sectionKey="unified_landing_page" />

        <ClientDisclaimer />

        <Hero
          isNextClicked={isNextClicked}
          isLoading={isLoading || jvp.isLoading || clientSurveyIsLoading}
          handleNext={handleNext}
          isAlexIdEnabled={isAlexIdEnabled}
          forceAlexID={forceAlexID}
          getStartedButtonRef={shouldUseModal ? modalTriggerButtonRef : null}
          getStartedButtonAccessibilityProps={shouldUseModal ? modalButtonAccessibilityProps : {}}
          userName={jvpUserData?.first_name || undefined}
        />

        {products.medicare && <MedicareCallout client={client} isNextClicked={isNextClicked} />}
      </PageLayout>

      <PageLayout bg={isNextClicked ? '--primary-white' : '--background-gray'} page="landing-footer">
        <Footer isNextClicked={isNextClicked} />
      </PageLayout>

      <ProductSelectModal
        isOpen={isOpen && !isNextClicked}
        onClose={onClose}
        chooseProduct={chooseProduct}
        handleNext={goToNext}
        showLanguageToggle={showLanguageToggle}
        modalTriggerButtonRef={modalTriggerButtonRef}
        modalAccessibilityProps={getDisclosureProps()}
      />
      {!hideOEBar && (
        <OpenEnrollmentBar
          isNextClicked={isNextClicked}
          openEnrollmentStartDate={jvp.upcoming?.publication.oe_start_date}
          openEnrollmentEndDate={jvp.upcoming?.publication.oe_end_date}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, UnifiedLandingPageStateProps>({
  client: makeSelectConfigField('client'),
  isUnderConstruction: makeSelectConfigField('is_under_construction'),
  forceAlexID: makeSelectConfigField('force_alex_id'),
  products: makeGetEnabledProducts(),
  bcUrlHash: makeSelectGlobalField('bcUrlHash'),
  isLoading: makeSelectGlobalField('isLoading'),
  jvp: makeSelectGlobalField('jvp'),
  clientSurveyIsLoading: makeSelectProfileField('clientSurveyIsLoading'),
  error: makeSelectGlobalField('error'),
  clientSurveyError: makeSelectProfileField('clientSurveyError'),
  showLanguageToggle: makeSelectConfigField('show_language_toggle'),
  isAlexIdEnabled: makeGetJvpHasPublicationWithFlag('alex_id_enabled'),
  jvpUserData: makeSelectGlobalField('jvpUser'),
});

export function mapDispatchToProps(dispatch): UnifiedLandingPageDispatchProps {
  return {
    editHouseholdExternalId: (externalId) => dispatch(setHouseholdExternalId(externalId)),
    next: (path) => dispatch(push(path)),
    chooseProduct: (product: AlexProducts) => dispatch(chooseProduct(product)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UnifiedLandingPage);
