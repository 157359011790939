import { isEmpty } from 'lodash';
import React from 'react';

import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { FieldDataTypes, SupplementalPlan } from 'Types/entities';

import { addDetails } from '../addContent';
import { dentalDetails } from '../dentalContent';
import FbsDetail from '../FbsDetail';
import { hospitalIndemnityDetails } from '../hospitalIndemnityContent';
import { lifeDetails } from '../lifeContent';
import { HiddenItem } from '../PlanContent/styled';
import { retirementDetails } from '../retirementContent';
import { visionDetails } from '../visionContent';

export interface HiddenContentProps {
  plan: SupplementalPlan;
  contentfulSupplementalPlan?: ContentfulSupplementalPlan;
  builderCustomerKey: string | null;
}

const hiddenItemList = (items: string[]) => {
  if (items?.length === 0) {
    return null;
  }

  return (
    <section className="benefits hidden-benefits">
      <Paragraph as="h6">
        <Text field="benefits_section.plan_card_text.payouts" />
      </Paragraph>
      {items.map((benefit, idx) => {
        const [key, val = ''] = benefit.split(':');
        return (
          <HiddenItem key={idx}>
            <Paragraph as="span" size="mini">
              <FbsDetail type={FieldDataTypes.STRING} data={key} />
            </Paragraph>
            <Paragraph as="span" size="mini">
              <FbsDetail type={FieldDataTypes.STRING} data={val} />
            </Paragraph>
          </HiddenItem>
        );
      })}
    </section>
  );
};

const legacyHiddenContent = (plan: SupplementalPlan) => (
  <>
    {plan.benefits.items.length > 0 && (
      <section className="benefits hidden-benefits legacy-hidden-content">
        <Paragraph as="h6">
          <Text field="benefits_section.plan_card_text.payouts" />
        </Paragraph>
        {plan.benefits.items.map((benefit, idx) => (
          <HiddenItem key={idx}>
            <Paragraph as="span" size="mini">
              {benefit.item.type ? (
                <FbsDetail type={benefit.item.type} data={benefit.item.data} />
              ) : (
                benefit.item
              )}
            </Paragraph>
            {!isEmpty(benefit.description) ? (
              <Paragraph as="span" size="mini">
                <FbsDetail type={benefit.description.type} data={benefit.description.data} />
              </Paragraph>
            ) : (
              <Paragraph as="span" size="mini" className="see-full-details-text">
                <Text field="benefits_section.plan_card_text.benefits.seeFullDetails" />
              </Paragraph>
            )}
          </HiddenItem>
        ))}
      </section>
    )}

    {plan.benefits.exclusions && plan.benefits.exclusions.length > 0 && (
      <section className="exclusions-details">
        <Paragraph size="small" weight="bold" as="h6">
          <Text field="benefits_section.plan_card_text.exclusions.title" />
        </Paragraph>
        {plan.benefits.exclusions.map((exclusion, idx) => (
          <div key={idx}>
            <Paragraph size="small">
              {exclusion.type ? <FbsDetail type={exclusion.type} data={exclusion.data} /> : exclusion}
            </Paragraph>
          </div>
        ))}
      </section>
    )}
  </>
);

const HiddenContent = ({ plan, contentfulSupplementalPlan, builderCustomerKey }: HiddenContentProps) => {
  if (!builderCustomerKey) {
    return legacyHiddenContent(plan);
  }

  if (contentfulSupplementalPlan?.benefit_items && contentfulSupplementalPlan?.benefit_items.length > 0) {
    return hiddenItemList(contentfulSupplementalPlan.benefit_items);
  }

  if (plan.benefits.items.length > 0) {
    switch (plan.plan_type) {
      case 'add':
        return addDetails(plan);
      case 'dental':
        return dentalDetails(plan, contentfulSupplementalPlan);
      case 'vision':
        return visionDetails(plan, contentfulSupplementalPlan);
      case 'hospital':
        return hospitalIndemnityDetails(plan);
      case 'life':
        return lifeDetails(plan);
      case '401k':
      case '403b':
      case 'retirement':
        return retirementDetails(plan);
      default:
        break;
    }
  }

  if (contentfulSupplementalPlan) {
    return hiddenItemList(contentfulSupplementalPlan.benefit_items);
  }

  return legacyHiddenContent(plan);
};

export default HiddenContent;
