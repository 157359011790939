import React from 'react';

import { TextField } from 'Containers/App/types';
import FbsDetail from 'Containers/ViewBenefitPage/ExpandablePlanCard/FbsDetail';
import { ListItem } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/styled';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { FormattedTooltipProps } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { Numbers } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';
import { FieldDataTypes, SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

interface SupplementalPlanMaximumPayoutProps {
  plan: SupplementalPlan;
  customLabelField?: TextField;
  customTooltipText?: string;
  customTooltipTitle?: string;
  hideTooltipTitle?: boolean;
}

export const SupplementalPlanMaximumPayout = ({
  plan,
  customLabelField,
  customTooltipText,
  customTooltipTitle,
  hideTooltipTitle,
}: SupplementalPlanMaximumPayoutProps) => {
  const benefits = transformBenefitItems(plan);
  const maxIsPerPerson = 'max_is_per_person' in benefits ? benefits.max_is_per_person : false;
  const maxPayout = plan?.maximum_payout || null;

  const { retrieveContentfulData } = useTextContext();
  const defaultTooltip = retrieveContentfulData<FormattedTooltipProps>(
    'benefits_section_additional.tooltips.max_annual_benefit',
  );

  const maxPayoutLabelField = customLabelField || 'benefits_section.plan_card_text.benefits.maxBenefit';
  const maxPayoutTooltipText = customTooltipText || defaultTooltip.text;

  let maxPayoutTooltipTitle: string | React.ReactNode | undefined;
  if (!hideTooltipTitle) {
    maxPayoutTooltipTitle = customTooltipTitle || defaultTooltip.title;
  }

  if (maxPayout) {
    return (
      <ListItem className="max-payout" data-testid="max-payout">
        <div className="item">
          <TooltipPlanDetails
            tooltip={{
              title: maxPayoutTooltipTitle,
              text: maxPayoutTooltipText,
            }}
            keyHint={`plan-${plan.external_id}-max-benefit`}
          >
            <Text field={maxPayoutLabelField} />
          </TooltipPlanDetails>
          {maxIsPerPerson ? (
            <FbsDetail
              type={FieldDataTypes.STRING}
              data={
                <Text field="benefits_section_dental.per_person" vars={{ x: toDollars(maxPayout.data) }} />
              }
            />
          ) : (
            <Numbers>
              <FbsDetail type={maxPayout.type} data={maxPayout.data} />
            </Numbers>
          )}
        </div>
      </ListItem>
    );
  }
  return null;
};
