/**
 * Create the store with dynamic reducers
 */

/* eslint-disable import/no-import-module-exports */
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { DEPLOY_ENVIRONMENTS } from './constants';
import createReducer from './reducers';
/* eslint-enable import/no-import-module-exports */

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history, deployEnvironment: DEPLOY_ENVIRONMENTS) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const isProduction = deployEnvironment === DEPLOY_ENVIRONMENTS.PROD;

  // In lieu of more detailed scrubbing, we're choosing to explicitly disable certain features in production.
  // https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
  const reduxEnhancerConfig = {
    attachReduxState: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    actionTransformer: (_action) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stateTransformer: (_state) => null,
  };

  const reduxEnhancer = Sentry.createReduxEnhancer(isProduction ? reduxEnhancerConfig : {});
  const enhancers = [applyMiddleware(...middlewares), reduxEnhancer];

  const store = createStore(createReducer(), initialState, composeWithDevToolsDevelopmentOnly(...enhancers));

  // Extensions
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.injectedReducers = {}; // Reducer registry
  // @ts-ignore
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // @ts-ignore
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  return store;
}
