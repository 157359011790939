import React from 'react';

import { TextField } from 'Containers/App/types';
import FbsDetail from 'Containers/ViewBenefitPage/ExpandablePlanCard/FbsDetail';
import { ListItem } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/styled';
import { FormattedTooltipProps } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { Numbers } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';

interface SupplementalPlanDeductibleProps {
  plan: SupplementalPlan;
  customLabelField?: TextField;
  customTooltipText?: string;
  customTooltipTitle?: string;
  hideTooltipTitle?: boolean;
}

const SupplementalPlanDeductible = ({
  plan,
  customLabelField,
  customTooltipText,
  customTooltipTitle,
  hideTooltipTitle,
}: SupplementalPlanDeductibleProps) => {
  const { retrieveContentfulData } = useTextContext();

  const labelField: TextField = customLabelField || 'benefits_section.plan_card_text.benefits.deductible';
  const defaultTooltip = retrieveContentfulData<FormattedTooltipProps>('tool_tips.deductible');
  const deductibleTooltipText = customTooltipText || defaultTooltip.text;

  let deductibleTooltipTitle: string | React.ReactNode | undefined;
  if (!hideTooltipTitle) {
    deductibleTooltipTitle = customTooltipTitle || defaultTooltip.title;
  }

  if (plan.deductible) {
    return (
      <ListItem className="supplemental-deductible">
        <div className="item">
          <TooltipPlanDetails
            tooltip={{
              title: deductibleTooltipTitle,
              text: deductibleTooltipText,
            }}
            keyHint={`plan-${plan.external_id}-deductible`}
          >
            <Text field={labelField} />
          </TooltipPlanDetails>
          <Numbers>
            <FbsDetail type={plan.deductible.type} data={plan.deductible.data} />
          </Numbers>
        </div>
      </ListItem>
    );
  }

  return null;
};

export default SupplementalPlanDeductible;
