import { Flex } from '@chakra-ui/react';
import React from 'react';

import { BenefitPeriod, VisionBenefits, VisionCoverageType } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

const visionBenefitPeriodText = (benefitPeriod?: BenefitPeriod) => {
  const { retrieveContentfulData } = useTextContext();

  switch (benefitPeriod) {
    case 'CalendarYear':
      return retrieveContentfulData<string>('benefits_section_vision.benefit_period_calendar_year');
    case 'Rolling12Months':
      return retrieveContentfulData<string>('benefits_section_vision.benefit_period12_months');
    case 'PlanYear':
      return retrieveContentfulData<string>('benefits_section_vision.benefit_period_plan_year');
    case 'TwoYears':
      return retrieveContentfulData<string>('benefits_section_vision.benefit_period_two_years');
    default:
      return '';
  }
};

const getCoverageFlatAmount = (visionBenefits: VisionBenefits) => {
  const { locale, retrieveContentfulData } = useTextContext();
  const visionTags = getVisionTagList(visionBenefits).map((tag) =>
    retrieveContentfulData<string>(tag.props.field).toLowerCase(),
  );
  const visionCoveredBenefits = new Intl.ListFormat(locale).format(visionTags);

  return (
    visionBenefits.flat_amount_vision && (
      <div className="flat-amount vision-plan">
        <Text
          field="benefits_section_vision.flat_amount"
          vars={{
            flatAmount: toDollars(visionBenefits.flat_amount_max_coverage),
            benefitPeriod: visionBenefitPeriodText(visionBenefits.flat_amount_benefit_period),
            coveredBenefits: visionCoveredBenefits,
          }}
        />
      </div>
    )
  );
};

const coverageText = (
  coverageType?: VisionCoverageType,
  copay?: string,
  coinsurance?: string,
  benefitPeriod?: BenefitPeriod,
  flatAmount?: string,
  discountAmount?: string,
) => {
  switch (coverageType) {
    case 'Allowance':
      return (
        <Text
          field="benefits_section_vision.allowance_coverage"
          vars={{
            allowance: toDollars(flatAmount),
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
          }}
        />
      );
    case 'AllowanceAndDiscount':
      return (
        <Text
          field="benefits_section_vision.allowance_with_discount_coverage"
          vars={{
            allowance: toDollars(flatAmount),
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
            discount: discountAmount,
          }}
        />
      );
    case 'Copay':
      return (
        <Text
          field="benefits_section_vision.copay_coverage"
          vars={{
            copay: toDollars(copay),
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
          }}
        />
      );
    case 'CopayAllowance':
      return (
        <Text
          field="benefits_section_vision.copay_allowance_coverage"
          vars={{
            copay: toDollars(copay),
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
            allowance: toDollars(flatAmount),
          }}
        />
      );
    case 'Coins':
      return (
        <Text
          field="benefits_section_vision.coinsurance_coverage"
          vars={{
            coinsurance,
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
          }}
        />
      );
    case 'CopayThenCoins':
      return (
        <Text
          field="benefits_section_vision.copay_coinsurance_coverage"
          vars={{
            copay: toDollars(copay),
            coinsurance,
            benefitPeriod: visionBenefitPeriodText(benefitPeriod),
          }}
        />
      );
    case 'FullCoverage':
      return (
        <Text
          field="benefits_section_vision.full_coverage"
          vars={{ benefitPeriod: visionBenefitPeriodText(benefitPeriod) }}
        />
      );
    default:
      return <Text field="benefits_section_vision.not_covered" />;
  }
};

const framesSelectionLanguage = (benefits: VisionBenefits) => {
  if (benefits.frames_coverage === 'Copay') {
    if (benefits.frames_has_specific_selection) {
      if (benefits.frames_has_non_selection_allowance) {
        return (
          <Text
            field="benefits_section_vision.frames_collection_allowance_coverage"
            vars={{
              collectionName: benefits.frames_specific_selection_name,
              allowance: toDollars(benefits.frames_non_selection_allowance_amount),
            }}
          />
        );
      }
      return (
        <Text
          field="benefits_section_vision.frames_collection_coverage_no_allowance"
          vars={{ collectionName: benefits.frames_specific_selection_name }}
        />
      );
    }
    return <Text field="benefits_section_vision.any_frames_covered" />;
  }

  return null;
};

export const visionDetails = (
  plan: SupplementalPlan,
  contentfulSupplementalPlan: ContentfulSupplementalPlan | undefined,
) => {
  const benefits = transformBenefitItems(plan) as VisionBenefits;

  return (
    <>
      {getCoverageFlatAmount(benefits)}
      <section className="benefits hidden-benefits vision-plan">
        <Paragraph as="h6">
          <Text field="benefits_section.plan_card_text.payouts" />
        </Paragraph>
        {!benefits.flat_amount_vision && (
          <>
            <HiddenItem className="eye-exams">
              <Paragraph size="mini" className="key">
                <Text field="benefits_section_vision.eye_exams" />
              </Paragraph>
              <Paragraph size="mini" className="value">
                {coverageText(
                  benefits.exams_coverage,
                  benefits.exams_copay,
                  benefits.exams_coinsurance,
                  benefits.exams_benefit_period,
                  benefits.exams_flat_amount,
                  benefits.exams_discount_amount,
                )}
              </Paragraph>
            </HiddenItem>
            <HiddenItem className="lenses">
              <Paragraph size="mini" className="key">
                <Text field="benefits_section_vision.lenses" />
              </Paragraph>
              <Paragraph size="mini" className="value">
                {coverageText(
                  benefits.lenses_coverage,
                  benefits.lenses_copay,
                  benefits.lenses_coinsurance,
                  benefits.lenses_benefit_period,
                  benefits.lenses_flat_amount,
                  benefits.lenses_balance_discount_amount,
                )}
                {benefits.bifocals_coverage && (
                  <>
                    &nbsp;
                    <Text field="benefits_section_vision.bifocals_coverage" />
                  </>
                )}
              </Paragraph>
            </HiddenItem>
            <HiddenItem className="frames">
              <Paragraph size="mini" className="key">
                <Text field="benefits_section_vision.frames" />
              </Paragraph>
              <Paragraph size="mini" className="value">
                {coverageText(
                  benefits.frames_coverage,
                  benefits.frames_copay,
                  benefits.frames_coinsurance,
                  benefits.frames_benefit_period,
                  benefits.frames_flat_amount,
                  benefits.frames_balance_discount_amount,
                )}

                <br />
                {framesSelectionLanguage(benefits)}
              </Paragraph>
            </HiddenItem>
            {benefits.contacts_is_flat_amount ? (
              <HiddenItem className="contacts">
                <Paragraph size="mini" className="key">
                  <Text field="benefits_section_vision.contact_lenses" />
                </Paragraph>
                <Paragraph size="mini" className="value">
                  <Text
                    field="benefits_section_vision.contacts_flat_amount"
                    vars={{
                      flatAmount: toDollars(benefits.contacts_flat_amount),
                      benefitPeriod: visionBenefitPeriodText(benefits.contacts_benefit_period),
                    }}
                  />
                </Paragraph>
              </HiddenItem>
            ) : (
              <>
                <HiddenItem className="contacts-exams">
                  <Paragraph size="mini" className="key">
                    <Text field="benefits_section_vision.contact_exams_and_fittings" />
                  </Paragraph>
                  <Paragraph size="mini" className="value">
                    {coverageText(
                      benefits.contacts_exam_coverage,
                      benefits.contacts_exam_copay,
                      benefits.contacts_exam_coinsurance,
                      benefits.contacts_exam_benefit_period,
                      benefits.contacts_exam_flat_amount,
                      benefits.contacts_exam_discount_amount,
                    )}
                  </Paragraph>
                </HiddenItem>
                <HiddenItem className="contacts-materials">
                  <Paragraph size="mini" className="key">
                    <Text field="benefits_section_vision.contact_lenses" />
                  </Paragraph>
                  <Paragraph size="mini" className="value">
                    {coverageText(
                      benefits.contacts_materials_coverage,
                      benefits.contacts_materials_copay,
                      benefits.contacts_materials_coinsurance,
                      benefits.contacts_materials_benefit_period,
                      benefits.contacts_materials_flat_amount,
                      benefits.contacts_materials_balance_discount_amount,
                    )}
                    <br />
                    {benefits.allow_contacts_and_glasses ? (
                      <RichText field="benefits_section_vision.contacts_and_glasses_together" />
                    ) : (
                      <RichText field="benefits_section_vision.contacts_and_glasses_separate" />
                    )}
                  </Paragraph>
                </HiddenItem>
              </>
            )}
          </>
        )}

        {benefits.network_name && (
          <>
            <HiddenItem className="network-info">
              <Paragraph size="normal">
                <Text field="benefits_section_additional.network_info_header" />
              </Paragraph>
            </HiddenItem>
            <HiddenItem className="network-info">
              <Paragraph as="span" size="mini">
                <Text field="benefits_section_additional.network_name" />
              </Paragraph>
              <Paragraph as="span" size="mini">
                {benefits.network_name}
              </Paragraph>
            </HiddenItem>
          </>
        )}

        {benefits.provider_link && (
          <HiddenItem className="provider-finder">
            <Paragraph as="span" size="mini">
              <Text field="benefits_section_additional.provider_directory" />
            </Paragraph>
            <Flex
              className="value"
              gap={2}
              sx={{
                a: {
                  fontSize: '14px !important',
                },
              }}
            >
              <Button url={benefits.provider_link} buttonType="link" size="xsmall">
                <Text field="benefits_section_additional.provider_link" />
              </Button>
              {contentfulSupplementalPlan?.provider_finder_instructions && (
                <Paragraph as="span" size="mini">
                  {contentfulSupplementalPlan.provider_finder_instructions}
                </Paragraph>
              )}
            </Flex>
          </HiddenItem>
        )}
      </section>
    </>
  );
};

export const getVisionTagList = (visionBenefits: VisionBenefits): JSX.Element[] => {
  const tags = [<Text key="exams" field="benefits_section_vision.eye_exams" />];

  if (visionBenefits.flat_amount_glasses) {
    tags.push(<Text field="benefits_section_vision.glasses" />);
  }
  if (visionBenefits.lenses_covered) {
    tags.push(<Text field="benefits_section_vision.lenses" />);
  }
  if (visionBenefits.frames_covered) {
    tags.push(<Text field="benefits_section_vision.frames" />);
  }
  if (visionBenefits.contacts_is_flat_amount || visionBenefits.flat_amount_contacts) {
    tags.push(<Text field="benefits_section_vision.contacts" />);
  }
  if (visionBenefits.contacts_exam_covered) {
    tags.push(<Text field="benefits_section_vision.contacts_exams" />);
  }
  if (visionBenefits.contacts_materials_covered) {
    tags.push(<Text field="benefits_section_vision.contact_lenses" />);
  }

  return tags;
};
