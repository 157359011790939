import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Warning = styled.div`
  display: flex;
  margin: 0 0 var(--sizing-md) 0;
  padding: var(--sizing-sm);
  background: var(--colors-blue-50);
  border-bottom: 2px solid var(--colors-blue-200);
  border-radius: var(--border-radius-xs);

  img {
    margin-right: 0.7rem;
  }
  span: {
    display: block;
  }
  span svg {
    margin-right: 8px;
  }
`;

export const FlexRow = styled.div`
  margin-top: var(--sizing-xs);
  display: flex;
  flex-direction: column;

  ${media.TABLET`
    flex-direction: row;
    justify-content: space-between;
  `}

  > span {
    text-align: left;
  }

  input {
    margin-right: var(--sizing-xs);
  }

  button {
    text-align: left;
    max-width: 50%;
    color: var(--primary-blue);

    &:hover {
      text-decoration: underline;
      background: var(--colors-blue-50);
    }
  }
`;
