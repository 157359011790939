import { Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { PetPlanContent } from 'Containers/OverviewPage/PetPlanContent';
import { MEMBER_TYPE } from 'Containers/ProfilePage/types';
import { SupplementalPlanCoveredBenefits } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/SupplementalPlanCoveredBenefits';
import SupplementalPlanDeductible from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/SupplementalPlanDeductible';
import { SupplementalPlanMaximumPayout } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/SupplementalPlanMaximumPayout';
import { RetirementBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { Benefits, FieldDataTypes, HouseholdMember, Items, SupplementalPlan } from 'Types/entities';

import { DetailsContainer, DetailsWrapper, ListItem, PlanDescription } from './styled';
import { addNotes } from '../addContent';
import FbsDetail from '../FbsDetail';
import { hospitalIndemnityAdditionalNotes } from '../hospitalIndemnityContent';
import { lifeAdditionalNotes } from '../lifeContent';
import { longTermDisabilityDescription } from '../ltdContent';
import PlanDetailsTable from '../PlanDetailsTable';
import { retirementAdditionalNotes } from '../retirementContent';
import { shortTermDisabilityDescription } from '../stdContent';

export interface PlanContentProps {
  plan: SupplementalPlan;
  contentfulSupplementalPlan?: ContentfulSupplementalPlan;
  showFbsCardDetails: boolean;
  employerName: string;
  builderCustomerKey: string | null;
  selectedHospitalIndemnityPlan?: SupplementalPlan | null;
  householdMembers: HouseholdMember[];
}

const PlanContent = ({
  plan,
  contentfulSupplementalPlan,
  showFbsCardDetails,
  employerName,
  builderCustomerKey,
  selectedHospitalIndemnityPlan,
  householdMembers,
}: PlanContentProps) => {
  const { retrieveContentfulData } = useTextContext();

  const insuranceType = plan.plan_type;
  const isAddPlan = insuranceType === 'add';
  const isDentalPlan = insuranceType === 'dental';
  const isVisionPlan = insuranceType === 'vision';
  const isHIPlan = insuranceType === 'hospital';
  const isLifePlan = insuranceType === 'life';
  const isStdPlan = insuranceType === 'std';
  const isLtdPlan = insuranceType === 'ltd';

  const isRetirementPlan = ['retirement', '401k', '403b'].includes(insuranceType);

  const shouldShowCoveredBenefitsList =
    !['std', 'ltd', 'critical'].includes(insuranceType) || !builderCustomerKey;

  // Determine if plan.eligible_members contains a non-policyholder
  const hasDependentCoverage = useMemo(() => {
    if (!plan.eligible_members || !householdMembers) return false;

    return plan.eligible_members.some((memberId) =>
      householdMembers.some(
        (member) => member.external_id === memberId && member.member_type !== MEMBER_TYPE.POLICYHOLDER,
      ),
    );
  }, [plan.eligible_members, householdMembers]);

  const normalizeBenefitData = (): Benefits => {
    if (!isEmpty(contentfulSupplementalPlan?.benefit_items)) {
      const items = contentfulSupplementalPlan?.benefit_items.map((benefit) => {
        const [key, val = ''] = benefit.split(':');
        return {
          item: { data: key, type: FieldDataTypes.STRING },
          description: { data: val, type: FieldDataTypes.STRING },
        } as Items;
      });

      return { items } as Benefits;
    }

    return plan.benefits;
  };

  const normalizedBenefitData = normalizeBenefitData();

  const showDetailsContainer =
    (builderCustomerKey &&
      (isDentalPlan ||
        isVisionPlan ||
        isAddPlan ||
        !isEmpty(normalizedBenefitData.items) ||
        !isEmpty(normalizedBenefitData.exclusions))) ||
    !builderCustomerKey;

  const renderDentalHeaders = () => {
    if (isDentalPlan && plan.deductible) {
      return <SupplementalPlanDeductible plan={plan} />;
    }
    return null;
  };

  const renderMaximumPayout = () => {
    if (plan.maximum_payout && (!builderCustomerKey || isDentalPlan)) {
      return <SupplementalPlanMaximumPayout plan={plan} />;
    }
    return null;
  };

  const renderCoveredBenefits = () =>
    !isEmpty(normalizedBenefitData.items) &&
    shouldShowCoveredBenefitsList && (
      <SupplementalPlanCoveredBenefits
        plan={plan}
        contentfulSupplementalPlan={contentfulSupplementalPlan}
        normalizedBenefitData={normalizedBenefitData}
        showFbsCardDetails={showFbsCardDetails}
        builderCustomerKey={builderCustomerKey}
      />
    );

  const renderCoveredConditionsTable = () => {
    if (contentfulSupplementalPlan?.covered_conditions_table_data) {
      return (
        <PlanDetailsTable
          header={retrieveContentfulData<string>(
            'benefits_section_additional.covered_conditions_table_header',
          )}
          subheader={retrieveContentfulData<string>(
            'benefits_section_additional.covered_conditions_table_subheader',
          )}
          columns={contentfulSupplementalPlan?.covered_conditions_table_data}
        />
      );
    }

    return null;
  };

  const renderCoverageLevelTable = () => {
    if (contentfulSupplementalPlan?.coverage_level_table_data) {
      return (
        <PlanDetailsTable
          header={retrieveContentfulData<string>('benefits_section_additional.coverage_level_table_header')}
          subheader={retrieveContentfulData<string>(
            'benefits_section_additional.coverage_level_table_subheader',
          )}
          columns={contentfulSupplementalPlan?.coverage_level_table_data}
        />
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (!builderCustomerKey && plan?.benefits?.description) {
      return (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </Paragraph>
      );
    }

    if (contentfulSupplementalPlan?.description) {
      return (
        <PlanDescription>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            <RichText
              noWrapper={false}
              field={undefined}
              fieldContent={contentfulSupplementalPlan.description}
            />
          </Paragraph>
        </PlanDescription>
      );
    }

    if (isAddPlan) {
      return addNotes(plan, hasDependentCoverage);
    }

    // Only render for real HI modules, not text-only modules
    if (isHIPlan && plan.benefits.items.length > 0) {
      const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
        'benefits_section_additional.supplemental_plans',
        [],
      );
      const selectedPlanData = contentfulSupplementalPlans.find(
        ({ plan_id }) => plan_id === selectedHospitalIndemnityPlan?.external_id,
      );
      return hospitalIndemnityAdditionalNotes(plan, selectedPlanData);
    }

    if (isLifePlan) {
      return lifeAdditionalNotes(plan, hasDependentCoverage);
    }

    if (isStdPlan && plan.benefits.items.length > 0) {
      return shortTermDisabilityDescription(plan, employerName);
    }

    if (isLtdPlan && plan.benefits.items.length > 0) {
      return longTermDisabilityDescription(plan, employerName);
    }

    if (isRetirementPlan) {
      const benefits = transformBenefitItems(plan) as RetirementBenefits;
      return retirementAdditionalNotes(benefits.plan_type, employerName);
    }

    if (!contentfulSupplementalPlan && plan?.benefits?.description) {
      return (
        <PlanDescription>
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </PlanDescription>
      );
    }

    return null;
  };

  if (plan.plan_type === 'pet') {
    return <PetPlanContent plan={plan} contentfulSupplementalPlan={contentfulSupplementalPlan} />;
  }

  return (
    <DetailsWrapper>
      <Stack gap={6}>
        {showDetailsContainer && (
          <DetailsContainer>
            {renderDentalHeaders()}
            {renderMaximumPayout()}
            {renderCoveredBenefits()}
            {!isEmpty(normalizedBenefitData.exclusions) && (
              <ListItem className="exclusions">
                <Paragraph lineHeight={1.75} size="small">
                  <Text field="benefits_section.plan_card_text.exclusions.instructions" />
                </Paragraph>
              </ListItem>
            )}
          </DetailsContainer>
        )}
        {renderCoveredConditionsTable()}
        {renderCoverageLevelTable()}
        {renderDescription()}
      </Stack>
    </DetailsWrapper>
  );
};

export default PlanContent;
