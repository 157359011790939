import 'whatwg-fetch';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { GlobalReducerState } from 'app/reducers';
import GoogleLogo from 'Assets/other/google-logo.svg';
import LoginButton from 'Containers/Auth/LoginButton';
import LogoutButton from 'Containers/Auth/LogoutButton';
import SignupButton from 'Containers/Auth/SignupButton';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';
import Logger from 'Utils/logger';

import { ProfileContent, SignedInWithGoogle } from './styled';

export interface AlexIDHeaderProps {
  getStore: () => GlobalReducerState;
}
const AlexIDHeader = ({ getStore }: AlexIDHeaderProps) => {
  const { isAuthenticated, user } = useAuth0();
  const { device } = useViewport();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(false);
  const userName = user?.given_name || <Text field="top_level_navigation.alex_id_account_btn" />;
  const isUserSignedInWithGoogle = user?.sub?.includes('google-oauth2');
  const store = getStore?.();
  const { incentiveSurveyIsLoading } = store?.profilePage || {};
  const { isLoading: commercialAppLoading } = store?.commercialApp || {};
  const { isLoading: overviewPageLoading } = store?.overviewPage || {};
  const handleResetPassword = () => {
    const domain = window._env_.REACT_APP_AUTH0_DOMAIN || '';
    const data = {
      client_id: window._env_.REACT_APP_AUTH0_CLIENT_ID || '',
      email: user?.email,
      connection: 'Username-Password-Authentication',
    };

    fetch(`https://${domain}/dbconnections/change_password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setResetPasswordSent(true);
        } else {
          setPasswordResetError(true);
        }
      })
      .catch((e) => {
        setPasswordResetError(true);
        Logger.error(e);
      });
  };

  const handleUpdateName = () => {
    onOpen();
  };

  if (isAuthenticated) {
    return (
      <>
        <Popover placement="bottom-end" autoFocus={false}>
          <>
            <PopoverTrigger>
              <Button
                className={device !== 'mobile' ? '' : 'icon-only'}
                size="small"
                iconLeft="User"
                iconProps={{ color: '--primary-blue', size: 20 }}
                onClick={() => null}
                data-testid="user-profile-btn"
              >
                {device !== 'mobile' ? userName : undefined}
              </Button>
            </PopoverTrigger>
            <PopoverContent w={64} border="0" boxShadow="var(--shadow-large)">
              <ProfileContent>
                <div className="profile-header">
                  <Paragraph className="user-name">{userName}</Paragraph>
                  {user?.email && (
                    <Paragraph className="user-email" size="small">
                      {user.email}
                    </Paragraph>
                  )}
                  {isUserSignedInWithGoogle && (
                    <SignedInWithGoogle>
                      <Flex align="center" gap={2}>
                        <img src={GoogleLogo} alt="Google" />
                        <Paragraph size="mini">
                          <Text field="top_level_navigation.alex_id_google" />
                          {resetPasswordSent && (
                            <Paragraph size="mini">
                              <Text field="top_level_navigation.alex_id_reset_password_text" />
                            </Paragraph>
                          )}
                        </Paragraph>
                      </Flex>
                    </SignedInWithGoogle>
                  )}
                </div>
                <Stack className="profile-actions" p={2} gap={0}>
                  <Button buttonType="link" onClick={handleResetPassword}>
                    <Text field="top_level_navigation.alex_id_reset_password_btn" />
                  </Button>
                  <Button buttonType="link" onClick={handleUpdateName}>
                    <Text field="top_level_navigation.alex_id_update_btn" />
                  </Button>
                  {resetPasswordSent && (
                    <Paragraph size="mini">
                      <Text field="top_level_navigation.alex_id_reset_password_text" />
                    </Paragraph>
                  )}
                  {passwordResetError && (
                    <Paragraph size="mini">
                      <Text field="top_level_navigation.alex_id_reset_password_error" />
                    </Paragraph>
                  )}
                  {!incentiveSurveyIsLoading && !commercialAppLoading && !overviewPageLoading && (
                    <LogoutButton buttonType="link" testId="header-logout-button">
                      <Text field="top_level_navigation.alex_id_logout_btn" />
                    </LogoutButton>
                  )}
                </Stack>
              </ProfileContent>
            </PopoverContent>
          </>
        </Popover>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={4}>
              <Paragraph>
                <Text field="top_level_navigation.alex_id_help" />
                <a href="mailto:help@myalex.com">help@myalex.com</a>
              </Paragraph>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <Flex gap={2}>
      <LoginButton size="small" getStore={getStore} testId="header-login-button">
        <Text field="top_level_navigation.alex_id_login_btn" />
      </LoginButton>
      <SignupButton size="small" getStore={getStore} testId="header-signup-button">
        <Text field="top_level_navigation.alex_id_signup_btn" />
      </SignupButton>
    </Flex>
  );
};

export default AlexIDHeader;
